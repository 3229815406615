<template>
  <div class="my-5">
    <v-card class="mt-5">
      <div class="mx-3 my-2">
        <h2>Processos Judiciais</h2>

        <div v-if="data.demandant_data.rows.length > 0">
          <h3>
            Como demandante (R$
            {{ SimpleMaskMoney.formatToCurrency(data.demandant_total) }})
          </h3>
          <table>
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header"
                  :class="{
                    'right-align': isRight(rightHeader, header),
                  }"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in data.demandant_data.rows" :key="index">
                <td
                  v-for="(cell, index) in row"
                  :key="index"
                  :class="{
                    'right-align': index == 8,
                  }"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>Sem processos como demandante!</p>
        </div>

        <div class="my-3"></div>

        <div v-if="data.defendant_data.rows.length > 0">
          <h3>
            Como demandado (R$
            {{ SimpleMaskMoney.formatToCurrency(data.defendant_total) }})
          </h3>
          <table>
            <thead>
              <tr>
                <th
                  v-for="header in headers"
                  :key="header"
                  :class="{
                    'right-align': isRight(rightHeader, header),
                  }"
                >
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in data.defendant_data.rows" :key="index">
                <td
                  v-for="(cell, index) in row"
                  :key="index"
                  :class="{
                    'right-align': index == 8,
                  }"
                >
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>Sem processos como demandado!</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
import SimpleMaskMoney from "simple-mask-money";

interface Table {
  rows: Array<object>;
}

interface JudicialData {
  demandant_total: string;
  demandant_data: Table;
  defendant_total: string;
  defendant_data: Table;
}

const rightHeader = ["Vlr (R$)", "value"];
const headers = [
  "CNJ",
  "Demandante",
  "Demandado",
  "Assunto",
  "Início",
  "Origem",
  "Status Predito",
  "Data Distrib.",
  "Vlr (R$)",
  "Sigilo",
];
defineProps<{
  data: JudicialData;
}>();

function isRight(columns: string[], value: string) {
  return columns.includes(value);
}
</script>
