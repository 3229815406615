import { RouteRecordRaw } from "vue-router";
import LoginView from "@/modules/users/LoginView.vue";
import NavbarView from "@/components/NavbarView.vue";
import SearchView from "@/modules/search/SearchView.vue";
import AdminHomeView from "@/modules/admin/AdminHomeView.vue";
import UsersView from "@/modules/users/UsersView.vue";
import CompaniesView from "@/modules/users/CompaniesView.vue";
import OrganizationsView from "@/modules/users/OrganizationsView.vue";
import SearchReportView from "@/modules/search/SearchReportView.vue";
import ImportSIGEFAreaView from "@/modules/search/ImportSIGEFAreaView.vue";
import SearchesView from "@/modules/search/SearchesView.vue";

const routes: Array<RouteRecordRaw> = [
  {
    path: "",
    name: "home",
    component: NavbarView,
    redirect: "search",
    children: [
      {
        path: "import_sigef",
        name: "import_sigef",
        component: ImportSIGEFAreaView,
        meta: {
          title: "Importação de SIGEF | FarmRISK",
        },
      },
      {
        path: "search",
        name: "search",
        component: SearchView,
        meta: {
          title: "Pesquisa | FarmRISK",
        },
      },
      {
        path: "search/:search_id(\\d+)",
        name: "serch_report",
        component: SearchReportView,
        meta: {
          title: "Relatório | FarmRISK",
        },
      },
      {
        path: "searches",
        name: "searches",
        component: SearchesView,
        meta: {
          title: "Pesquisas | FarmRISK",
        },
      },
      {
        path: "users",
        name: "users",
        component: UsersView,
        meta: {
          title: "Usuários | FarmRISK",
        },
      },
      {
        path: "organizations",
        name: "organizations",
        component: OrganizationsView,
        meta: {
          title: "Organizações | FarmRISK",
        },
      },
      {
        path: "companies",
        name: "companies",
        component: CompaniesView,
        meta: {
          title: "Empresas | FarmRISK",
        },
      },
    ],
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminHomeView,
    meta: {
      title: "Admin | FarmRISK",
    },
  },
  {
    path: "/login",
    name: "login",
    component: LoginView,
    meta: {
      title: "FarmRISK - Login",
    },
    props: (route) => ({ ...route.query, ...route.params }),
  },
];

export default routes;
