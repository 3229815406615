<template>
  <div class="my-5">
    <v-card>
      <v-card-title>Sócios</v-card-title>
      <div class="mx-3 my-2">
        <div v-if="data.length > 0">
          <table>
            <thead>
              <tr>
                <th v-for="header in headers" :key="header">
                  {{ header }}
                </th>
              </tr>
            </thead>
            <tbody>
              <tr v-for="(row, index) in data" :key="index">
                <td v-for="(cell, index) in row" :key="index">
                  {{ cell }}
                </td>
              </tr>
            </tbody>
          </table>
        </div>
        <div v-else>
          <p>Sem informações de sócios disponíveis!</p>
        </div>
      </div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
interface PartnerData {
  name: string;
  document: string;
  role: string;
  start_date: string;
}

const headers = ["Documento", "Nome/Razão Social", "Cargo", "Data Inclusão"];

defineProps<{
  data: Array<PartnerData>;
}>();
</script>
