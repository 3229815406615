<template>
  <v-container fluid class="bg-white">
    <h1>Buscas anteriores</h1>
    <SimpleTable
      :url-base="urlBase"
      :messages="dataMessages"
      :headers="tableHeaders"
      :items-process="searchesProcess"
      @create-opened="loadAll"
      @edit-opened="loadAll"
    >
    </SimpleTable>
  </v-container>
</template>

<script setup lang="ts">
import { localeDateTimeOptions } from "@/consts";
import SimpleTable from "@/components/SimpleTable.vue";
import { useLoadSearches } from "@/composables/load-searches";

const urlBase = "/searches";
const dataMessages = {
  unexpectedError:
    "Erro inesperado. Tente novamente ou entre em contato com o suporte.",
};

const tableHeaders = [
  {
    title: "ID",
    key: "id",
  },
  {
    title: "Nome",
    key: "name",
  },
  {
    title: "Documento",
    key: "document",
  },
  {
    title: "Tipo",
    key: "type",
  },
  {
    title: "Data",
    key: "created_at",
  },
  {
    title: "Empresa",
    key: "company.name",
  },
  {
    title: "Usuário",
    key: "user.full_name",
  },
  {
    title: "Ações",
    key: "actions",
    sortable: false,
    align: "end",
  },
];

const { searches, loadingSearches, load: loadSearches } = useLoadSearches();

function searchesProcess(searches: any[]) {
  return searches.map((searches) => {
    searches.created_at = new Date(searches.created_at).toLocaleString(
      undefined,
      localeDateTimeOptions
    );
    searches.document = formatDocument(searches.type, searches.document);
    searches.type = searches.type.toUpperCase();
    return searches;
  });
}

function formatDocument(type: string, document: string) {
  if (type == "cpf") {
    return document.replace(/(\d{3})(\d{3})(\d{3})(\d{2})/, "$1.$2.$3-$4");
  }
  return document.replace(
    /(\d{2})(\d{3})(\d{3})(\d{4})(\d{2})/,
    "$1.$2.$3/$4-$5"
  );
}

async function loadAll() {
  await Promise.all([loadSearches()]);
}
</script>
