<template>
  <v-overlay
    id="overlayElement"
    v-model="loadingData"
    scroll-strategy="block"
    location-strategy="static"
    persistent
  >
    <div class="text-center">
      <v-progress-circular
        color="primary"
        :size="70"
        :width="7"
        indeterminate
      ></v-progress-circular>
    </div>
    <p>Enviando dados...</p>
  </v-overlay>
  <v-container fluid>
    <v-card>
      <v-card-text>
        <div
          class="d-flex flex-row mb-6 flex-column justify-center align-center"
        >
          <v-form v-model="form" @submit.prevent="submitFile">
            <div class="d-flex flex-column">
              <v-file-input
                accept=".zip"
                label="Arquivo ZIP"
                class="mx-2 px-2"
                style="min-width: 500px"
                v-model="file"
                :rules="[requiredFile]"
              >
              </v-file-input>
              <v-btn
                class="mx-2 px-2"
                color="primary"
                size="x-large"
                type="submit"
              >
                Enviar
              </v-btn>
            </div>
          </v-form>
        </div>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<style lang="scss">
#overlayElement .v-overlay__content {
  position: absolute;
  top: 65%;
  left: 50%;
  transform: translate(-50%, -50%);
}
</style>

<script setup lang="ts">
import axios from "axios";
import { ref } from "vue";
import { v4 as uuidv4 } from "uuid";
import { useRouter } from "vue-router";
import { useSnackbar } from "@/store";

const form = ref(false);
const { showSnackbar } = useSnackbar();
const searchButtonDisabled = ref(false);
const loadingData = ref(false);

const file = ref<File[]>();

function requiredFile(v: any) {
  return v.length == 1 || "Selecione um arquivo ZIP";
}

async function submitFile() {
  if (!file.value) return;
  searchButtonDisabled.value = true;
  loadingData.value = true;

  const chunk_size = 1024 * 1024 * 5; // 5MB
  let offset = 0;
  let chunk_number = 0;
  let file_content = file.value[0];
  const uuid_code = uuidv4();

  if (file_content) {
    try {
      while (offset < file_content?.size) {
        const chunk = file_content.slice(offset, offset + chunk_size);
        const chunk_blob = new Blob([chunk], { type: file_content.type });
        const formData = new FormData();
        formData.append("uuid_code", uuid_code);
        formData.append("sigef_file", chunk_blob);
        formData.append("name", file_content.name);
        formData.append("chunk_number", String(chunk_number));
        formData.append(
          "total_chunks",
          String(Math.ceil(file_content?.size / chunk_size))
        );
        await axios.post("/sigef_area", formData, {
          headers: { "Content-Type": "multipart/form-data" },
        });
        offset += chunk_size;
        chunk_number += 1;
      }
      showSnackbar("Arquivo ZIP importado com sucesso.", "success");
      searchButtonDisabled.value = false;
      loadingData.value = false;
    } catch (err) {
      showSnackbar("Erro no envio do arquivo.", "error");
      searchButtonDisabled.value = false;
      loadingData.value = false;
    } finally {
      file.value = [];
    }
  }
}
</script>
