<template>
  <div class="my-5">
    <v-card>
      <v-card-title>Vizinhos</v-card-title>
      <div class="my-2 mx-3" v-if="data.length > 0">
        <table>
          <thead>
            <tr>
              <th v-for="header in columnsHeader" :key="header">
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data" :key="row">
              <td v-for="(cell, index) in row" :key="index">
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-2 mx-3" v-else>Sem vizinhos cadastrados!</div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
const columnsHeader = ["Nome", "CPF", "Endereço", "Cidade"];
defineProps<{
  data: Array<object>;
}>();
</script>
