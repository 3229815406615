<template>
  <div class="my-5">
    <v-card>
      <v-card-title>Protestos</v-card-title>
      <div class="my-2 mx-3" v-if="data.length > 0">
        <p><span style="font-weight: bold">Total:</span>{{ total }}</p>
        <table>
          <thead>
            <tr>
              <th
                v-for="header in headers"
                :key="header"
                :class="{
                  'right-align': isRight(rightHeader, header),
                }"
              >
                {{ header }}
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="row in data" :key="row">
              <td
                v-for="(cell, index) in row"
                :key="index"
                :class="{
                  'right-align': isRight(rightHeader, index),
                }"
              >
                {{ cell }}
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="my-2 mx-3" v-else>Sem protestos encontrados!</div>
    </v-card>
  </div>
</template>

<script setup lang="ts">
const rightHeader = ["Valor", "value"];
const headers = ["Data", "Cartório", "Cidade/UF", "Valor"];
defineProps<{
  total: string;
  data: Array<object>;
}>();

function isRight(columns: string[], value: string) {
  return columns.includes(value);
}
</script>
